var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4","lg":"4","xl":"4"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('div',{staticClass:"mr-5"},[_c('h3',[_vm._v("Claimed")])])])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4","lg":"4","xl":"4"}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"mr-2 mt-5",attrs:{"label":"search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"gradient-bg white--text",attrs:{"small":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dense":""}},[_vm._v("mdi-export")]),_vm._v("Export ")],1)],1)],1)],1),_c('v-row',{staticClass:"mx-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","lg":"12","xl":"12"}},[_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"headers":_vm.QRData,"items":_vm.QRItems,"dense":"","fixed-header":_vm.fixed,"height":_vm.height,"search":_vm.search,"items-per-page":100,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.qr_created_on",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.qr_created_on != null ? _vm.get_date(item.qr_created_on) : "N/A"))])]}},{key:"item.qr_claimed_on",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.qr_claimed_on != null ? _vm.get_date(item.qr_claimed_on) : "N/A"))])]}},{key:"item.qr_location_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.qr_location_name != null && item.qr_location_name != undefined ? item.qr_location_name : "N/A")+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.qr_location_name != null && item.qr_location_name != undefined ? item.qr_location_name : "N/A")+" ")])])]}},{key:"item.organisation_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"overflow"},on),[_vm._v(" "+_vm._s(item.organisation_name != null && item.organisation_name != undefined ? item.organisation_name : "N/A")+" ")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.organisation_name != null && item.organisation_name != undefined ? item.organisation_name : "N/A")+" ")])])]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v(" No QR's have been Claimed yet ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true}],null,true)})],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="mt-5">
    <v-row no-gutters>
      <v-col cols="12" md="4" sm="4" lg="4" xl="4">
        <v-toolbar dense elevation="0">
          <div class="mr-5"><h3>Claimed</h3></div>
        </v-toolbar>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="4" sm="4" lg="4" xl="4">
        <v-toolbar dense class="elevation-0">
          <v-text-field
            v-model="search"
            label="search"
            class="mr-2 mt-5"
            dense
            append-icon="mdi-magnify"
          ></v-text-field>
          <v-btn small class="gradient-bg white--text">
            <v-icon dense class="mr-2">mdi-export</v-icon>Export
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-4">
      <v-col cols="12" md="12" sm="12" lg="12" xl="12">
        <v-data-table
          :headers="QRData"
          :items="QRItems"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :height="height"
          :search="search"
          :items-per-page="100"
          :loading="isLoading"
        >
          <template v-slot:[`item.qr_created_on`]="{ item }">
            <span>{{
              item.qr_created_on != null ? get_date(item.qr_created_on) : "N/A"
            }}</span>
          </template>
          <template v-slot:[`item.qr_claimed_on`]="{ item }">
            <span>{{
              item.qr_claimed_on != null ? get_date(item.qr_claimed_on) : "N/A"
            }}</span>
          </template>
          <template v-slot:[`item.qr_location_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span class="overflow" v-on="on">
                  {{
                    item.qr_location_name != null &&
                    item.qr_location_name != undefined
                      ? item.qr_location_name
                      : "N/A"
                  }}
                </span>
              </template>
              <span class="white--text"
                >{{
                  item.qr_location_name != null &&
                  item.qr_location_name != undefined
                    ? item.qr_location_name
                    : "N/A"
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.organisation_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span class="overflow" v-on="on">
                  {{
                    item.organisation_name != null &&
                    item.organisation_name != undefined
                      ? item.organisation_name
                      : "N/A"
                  }}
                </span>
              </template>
              <span class="white--text"
                >{{
                  item.organisation_name != null &&
                  item.organisation_name != undefined
                    ? item.organisation_name
                    : "N/A"
                }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No QR's have been Claimed yet
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { ListQrsDifferentStates } from "@/graphql/queries.js";
import { parseTime } from "@/utils";

export default {
  components: {
    Overlay,
    SnackBar,
  },
  data: () => ({
    QRData: [
      { text: "QR ID", width: "", value: "prznce_qr_count" },
      { text: "Org Name", width: "", value: "organisation_name" },
      { text: "QR Location Name", width: "", value: "qr_location_name" },
      { text: "Created On", width: "", value: "qr_created_on" },
      { text: "Claimed On", width: "", value: "qr_claimed_on" },
    ],
    QRItems: [],
    search: "",
    fixed: true,
    isLoading: false,
    height: 0,
    overlay: false,
    SnackBarComponent: {},
    QRStatus: "IN",
    QRStatusItems: [
      { text: "IN", value: "IN" },
      { text: "US", value: "US" },
      { text: "UAE", value: "UAE" },
    ],
  }),
  mounted() {
    this.height = window.innerHeight - 190;
    this.get_qr_list("CLAIMED");
  },
  methods: {
    exporttoexcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = ["QR ID", "QR Unique ID", "Claimed By"];
        const filterVal = ["qr_id", "prznce_qr_count", "organisation_name"];
        const list = this.QRItems;
        list.forEach(function (findx) {
          if (findx.qr_id != undefined) {
            findx.qr_id =
              "https://www.prezence.app/Apps/myprezence?qr_id=" + findx.qr_id;
          }
        });
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "Printed QRs List",
        });
      });
      //
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    async get_qr_list(qr) {
      this.QRItems = [];
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(ListQrsDifferentStates, {
            input: {
              qr_type: qr,
            },
          })
        );
        this.QRItems = result.data.ListQrsDifferentStates.data.Items;
        this.isLoading = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
  },
};
</script>

<style></style>
